@font-face {
  font-family: 'Crimson Text';
  src: url('../public/font.woff2') format('woff2');
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-weight: 500;
  font-family: 'Crimson Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: brandon-grotesque, sans-serif !important;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}

footer {
  bottom: 0;
  width: 100%;
  background-color: #00305d;
  color: white;
  font-weight: 400;
  margin-top: auto;
}
