.tiptap-toolbar {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding: 5px;
}

.tiptap-toolbar .ant-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tiptap {
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px 10px;
  background-color: white;
}

.rich-text-field-wrapper {
  background-color: #d9d9d9;
  border-radius: 6px;
}